define('ember-common/authorizers/ember-common', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		authorize: function authorize(sessionData, block) {
			block('X-Access-Token', sessionData.token);
		}
	});
});