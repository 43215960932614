define('ember-common/utils/router-setup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (self) {
		self.route('login');
		// self.route('callback');
		self.route('errorPage');
		// self.route('notifications', { path: 'notifications'});
		self.route('pageNotFound', { path: '/*wildcard' });
	};
});