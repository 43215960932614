define('ember-common/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var RESTAdapter = _emberData.default.RESTAdapter;
	exports.default = RESTAdapter.extend({
		authorizer: 'authorizer:token'
	});
});