define('ember-common/mixins/component-child', ['exports', 'ember-common/mixins/component-parent'], function (exports, _componentParent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({

		/**
   * flag to check if component has already been rendered, for the `_willRender` event handler
   * @property _didInsert
   * @type boolean
   * @private
   */
		_didInsert: false,

		/**
   * Register ourself as a child at the parent component
   * We use the `willRender` event here to also support the fastboot environment, where there is no `didInsertElement`
   *
   * @method _willRender
   * @private
   */
		_willRender: Ember.on('willRender', function () {
			if (!this._didInsert) {
				this._didInsert = true;
				var parent = this.nearestOfType(_componentParent.default);
				if (parent) {
					parent.registerChild(this);
					this.set('_parent', parent);
				}
			}
		}),

		/**
   * stores the parent in didInsertElement hook as a work-a-round for
   * https://github.com/emberjs/ember.js/issues/12080
   *
   * @property _parent
   * @private
   */
		_parent: null,

		/**
   * Unregister form the parent component
   *
   * @method _willDestroyElement
   * @private
   */
		_willDestroyElement: Ember.on('willDestroyElement', function () {
			var parent = this.nearestOfType(_componentParent.default) || this.get('_parent');
			if (parent) {
				parent.removeChild(this);
			}
		})
	});
});