define('ember-common/components/ht-table-query', ['exports', 'ember-common/components/ht-table', 'ember-common/templates/components/ht-table'], function (exports, _htTable, _htTable2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var get = Ember.get,
	    set = Ember.set,
	    setProperties = Ember.setProperties,
	    computed = Ember.computed,
	    typeOf = Ember.typeOf,
	    run = Ember.run,
	    extend = Ember.$.extend,
	    warn = Ember.Logger.warn;
	exports.default = _htTable.default.extend({

		// layout,

		/**
   * True if data is currently being loaded from the server.
   * Can be used in the template to e.g. display a loading spinner.
   *
   * @type {boolean}
   * @name isLoading
   */
		isLoading: false,

		/**
   * True if last data query promise has been rejected.
   * Can be used in the template to e.g. indicate stale data or to e.g. show error state.
   *
   * @type {boolean}
   * @name isError
   */
		isError: false,

		/**
   * The property on meta to load the pages count from.
   *
   * @type {string}
   * @name metaPagesCountProperty
   */
		metaPagesCountProperty: 'pagesCount',
		/**
   * The property on meta to load the total item count from.
   *
   * @type {string}
   * @name metaItemsCountProperty
   */
		metaItemsCountProperty: 'itemsCount',

		/**
   * The time to wait until new data is actually loaded.
   * This can be tweaked to avoid making too many server requests.
   *
   * @type {number}
   * @name {debounceDataLoadTime}
   */
		debounceDataLoadTime: 500,

		/**
   * The query parameters to use for server side filtering / querying.
   *
   * @type {object}
   * @name filterQueryParameters
   */
		filterQueryParameters: {
			globalFilter: 'search',
			sort: 'by',
			sortDirection: 'direction',
			page: 'page',
			pageSize: 'pageSize'
		},

		observedProperties: ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString'],

		/**
   * This is set during didReceiveAttr and whenever the page/filters change.
   */
		filteredContent: [],

		/**
   * For server side filtering, these two properties are the same as the filtered content.
   */
		visibleContent: computed.alias('arrangedContent'),
		arrangedContent: computed.alias('filteredContent'),

		/**
   * The total content length is get from the meta information.
   * Set metaItemsCountProperty to change from which meta property this is loaded.
   *
   * @type {number}
   * @name arrangedContentLength
   */
		arrangedContentLength: computed('filteredContent.meta', function () {
			var itemsCountProperty = get(this, 'metaItemsCountProperty');
			var meta = get(this, 'filteredContent.meta') || {};
			return get(meta, itemsCountProperty) || 0;
		}),

		/**
   * The pages count is get from the meta information.
   * Set metaPagesCountProperty to change from which meta property this is loaded.
   *
   * @type {number}
   * @name pagesCount
   */
		pagesCount: computed('filteredContent.meta', function () {
			var pagesCountProperty = get(this, 'metaPagesCountProperty');
			var meta = get(this, 'filteredContent.meta') || {};
			return get(meta, pagesCountProperty) || 1;
		}),

		/**
   * The index of the last item that is currently being shown.
   *
   * @type {number}
   * @name lastIndex
   */
		lastIndex: computed('pageSize', 'currentPageNumber', 'arrangedContentLength', function () {
			var pageMax = get(this, 'pageSize') * get(this, 'currentPageNumber');
			var itemsCount = get(this, 'arrangedContentLength');
			return Math.min(pageMax, itemsCount);
		}),

		_setupColumns: function _setupColumns() {
			this._super();

			if (get(this, 'processedColumns').any(function (x) {
				return get(x, 'filters.length') > 0;
			})) this._loadData();
		},


		/**
   * This function actually loads the data from the server.
   * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
   */
		_loadData: function _loadData() {
			var _this = this;

			var data = get(this, 'data');
			var currentPageNumber = get(this, 'currentPageNumber');
			var pageSize = get(this, 'pageSize');
			var columns = get(this, 'processedColumns');
			var sortProperties = get(this, 'sortProperties');
			var filterString = get(this, 'filterString');

			if (!get(data, 'query')) {
				warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data');
				return;
			}
			var query = extend({
				meta: {},
				filters: {}
			}, get(data, 'query'));

			var store = get(data, 'store');
			var modelName = get(data, 'type.modelName');

			// Add pagination information
			query.meta[get(this, 'filterQueryParameters.page')] = currentPageNumber;
			query.meta[get(this, 'filterQueryParameters.pageSize')] = pageSize;

			// Add sorting information
			var sort = sortProperties && get(sortProperties, 'firstObject');
			if (sort) {
				var _sort$split = sort.split(':'),
				    _sort$split2 = _slicedToArray(_sort$split, 2),
				    sortBy = _sort$split2[0],
				    sortDirection = _sort$split2[1];

				query.meta = this.sortingWrapper(query.meta, sortBy, sortDirection.toUpperCase());
			} else {
				delete query.meta[[get(this, 'filterQueryParameters.sort')]];
				delete query.meta[[get(this, 'filterQueryParameters.sortDirection')]];
			}

			// Add global filter
			var globalFilter = get(this, 'filterQueryParameters.globalFilter');
			if (filterString) {
				query.meta[globalFilter] = filterString;
			} else {
				delete query.meta[globalFilter];
			}

			// Add per-column filter
			columns.forEach(function (column) {
				var filter = get(column, 'filters');
				var filterTitle = _this.getCustomFilterTitle(column);

				if (filter) {
					query.filters[filterTitle] = filter;
				} else {
					delete query.filters[filterTitle];
				}
			});

			setProperties(this, { isLoading: true, isError: false });
			store.query(modelName, query).then(function (newData) {
				return setProperties(_this, { isLoading: false, isError: false, filteredContent: newData });
			}).catch(function () {
				return setProperties(_this, { isLoading: false, isError: true });
			});
		},

		/**
   * Wrapper for sorting query
   *
   * @param {object} query parameters
   * @param {string} sortBy
   * @param {string} sortDirection
   * @returns {object} query parameters
   */
		sortingWrapper: function sortingWrapper(query, sortBy, sortDirection) {
			query.sort = [{}];
			query.sort[0][get(this, 'filterQueryParameters.sort')] = sortBy;
			query.sort[0][get(this, 'filterQueryParameters.sortDirection')] = sortDirection;

			return query;
		},


		/**
   * Customize filter title
   *
   * @param {object} column Column object
   * @returns {string} title
   */
		getCustomFilterTitle: function getCustomFilterTitle(column) {
			return get(column, 'filteredBy') || get(column, 'propertyName');
		},


		actions: {
			gotoNext: function gotoNext() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var pagesCount = get(this, 'pagesCount');
				var currentPageNumber = get(this, 'currentPageNumber');
				if (pagesCount > currentPageNumber) {
					this.incrementProperty('currentPageNumber');
				}
			},
			gotoLast: function gotoLast() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var pagesCount = get(this, 'pagesCount');
				set(this, 'currentPageNumber', pagesCount);
			},
			sort: function sort(column) {
				var sortMap = {
					none: 'asc',
					asc: 'desc',
					desc: 'none'
				};
				var sortedBy = get(column, 'sortedBy');
				if (typeOf(sortedBy) === 'undefined') {
					sortedBy = get(column, 'propertyName');
				}
				if (!sortedBy) {
					return;
				}

				var currentSorting = get(column, 'sorting');
				var newSorting = sortMap[currentSorting.toLowerCase()];
				var sortingArgs = [column, sortedBy, newSorting];
				this._singleColumnSorting.apply(this, sortingArgs);
			}
		},

		didReceiveAttrs: function didReceiveAttrs() {
			set(this, 'filteredContent', get(this, 'data'));
		},
		_addPropertyObserver: function _addPropertyObserver() {
			run.debounce(this, this._loadData, get(this, 'debounceDataLoadTime'));
		},
		willInsertElement: function willInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);

			var observedProperties = get(this, 'observedProperties');
			observedProperties.forEach(function (propertyName) {
				return _this2.addObserver(propertyName, _this2._addPropertyObserver);
			});
		},
		willDestroyElement: function willDestroyElement() {
			var _this3 = this;

			this._super.apply(this, arguments);

			var observedProperties = get(this, 'observedProperties');
			observedProperties.forEach(function (propertyName) {
				return _this3.removeObserver(propertyName);
			});
		}
	});
});