define('ember-common/components/login-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    inject = Ember.inject,
	    computed = Ember.computed;
	exports.default = Component.extend({
		tagName: 'form',
		attributeBindings: ['onsubmit'],
		onsubmit: 'return false',
		session: inject.service(),
		isProcessing: false,
		disableLogin: computed('identification', 'password', function () {
			return !this.get('identification') && !this.get('password');
		})
	});
});