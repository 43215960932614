define('ember-common/instance-initializers/session-events', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize(applicationInstance) {
		var applicationRoute = applicationInstance.lookup('route:application');
		var session = applicationInstance.lookup('service:session');
		session.on('authenticationSucceeded', function () {
			var simpleAuth = _emberGetConfig.default['ember-simple-auth'];
			applicationRoute.transitionTo(simpleAuth && simpleAuth.routeAfterAuthentication ? simpleAuth.routeAfterAuthentication : 'index');
		});
		session.on('invalidationSucceeded', function () {
			applicationRoute.transitionTo('login');
		});
	}

	exports.default = {
		initialize: initialize,
		name: 'session-events',
		after: 'ember-simple-auth'
	};
});