define('ember-common/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Store.extend({
		// Not ready for use yet
		htRequest: function htRequest(data, options) {
			var _this = this;

			return new Promise(function (resolve, reject) {
				var _options = options || {};
				var host = _options.host || window.location.origin;
				var port = _options.port ? ':' + _options.port : '';
				var endpoint = _options.endpoint;
				var type = _options.type && typeof _options.type === 'string' ? _options.type.toUpperCase() : 'GET';

				if (!endpoint.startsWith('/')) endpoint = '/' + endpoint;

				Ember.$.ajax({
					url: '' + host + port + endpoint,
					type: type,
					data: data,
					accepts: 'application/json',
					headers: {
						'x-access-token': _this.token.token()
					}
				}).done(function (result) {
					return resolve(result);
				}).fail(function (e) {
					return reject(e);
				});
			});
		}
	});
});