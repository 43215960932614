define('ember-common/components/models-select', ['exports', 'ember-common/templates/components/models-select'], function (exports, _modelsSelect) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _modelsSelect.default,
		tagName: 'select',
		classNameBindings: ['cssPropertyName', 'fluid', 'selection'],
		classNames: 'ui dropdown',
		selection: false,
		fluid: false,
		cssPropertyName: '',
		change: function change() {
			this.set('value', this.$('option:selected').val());
		}
	});
});