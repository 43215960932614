define('ember-common/-private/column', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var observer = Ember.observer,
	    computed = Ember.computed,
	    isEmpty = Ember.isEmpty,
	    O = Ember.Object,
	    set = Ember.set,
	    get = Ember.get,
	    A = Ember.A;
	exports.default = O.extend({

		cssPropertyName: computed('propertyName', function () {
			return get(this, 'propertyName').replace(/\./g, '-');
		}),

		isVisible: computed.not('isHidden'),

		sortAsc: computed.equal('sorting', 'asc'),

		sortDesc: computed.equal('sorting', 'desc'),

		filterUsed: computed('filters', 'filters.[]', 'filters.@each', 'filters.length', function () {
			return get(this, 'filters.length') > 0;
		}),

		// dataType: computed('dataType'),

		/**
   * If preselected option doesn't exist after <code>filterOptions</code> update,
   * <code>filterString</code> is reverted to empty string (basically, filtering for this column is dropped)
   */
		cleanFilterString: observer('filterWithSelect', 'filterOptions.[]', function () {
			var filterOptions = get(this, 'filterOptions');
			var filterWithSelect = get(this, 'filterWithSelect');
			var filters = get(this, 'filters');
			if (!filterWithSelect || isEmpty(filterOptions)) {
				return;
			}
			if (!A(filterOptions).findBy('value', filters)) {
				set(this, 'filters', []);
			}
		})

	});
});