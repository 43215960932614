define('ember-common/services/token-data', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var get = Ember.get,
	    Service = Ember.Service,
	    inject = Ember.inject,
	    computed = Ember.computed,
	    getOwner = Ember.getOwner,
	    assert = Ember.assert;
	exports.default = Service.extend({
		session: inject.service(),
		tokenData: computed('session.session.content.authenticated', function () {
			var authenticator = getOwner(this).lookup('authenticator:jwt');
			var session = this.get('session.session.content.authenticated');
			var tokenData = {};

			if (session && Object.keys(session).length > 0) {
				return tokenData = authenticator.getTokenData(session.token);
			}
			return tokenData;
		}),
		init: function init() {
			this._super.init && this._super.init.apply(this, arguments);
		},
		token: function token() {
			return this.get('session').get('data.authenticated').token;
		},
		properties: function properties() {
			return this.get('tokenData');
		},
		belongsTo: function belongsTo() {
			var groups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
			var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			assert('Groups must be either an array or string', Array.isArray(groups) || typeof groups === 'string');
			assert('options must be an object', (typeof options === 'undefined' ? 'undefined' : _typeof(options)) === 'object');
			groups = Array.isArray(groups) ? groups : [groups];
			var tokenData = get(this, 'tokenData');
			var match = false;

			for (var x = 0; x < groups.length; x++) {
				if (tokenData && Array.isArray(tokenData.groups)) {
					match = tokenData.groups.some(function (y) {
						return y === groups[x];
					});
				}

				/*
     *	If we want to match all and one doesnt match, break early and return false
     *	If we want to match one and we find one, break early and return true
    */
				if (options.all && !match || !options.all && match) {
					break;
				}
			}
			return match;
		}
	});
});