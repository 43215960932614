define('ember-common/components/ht-table', ['exports', 'ember-common/utils/fmt', 'ember-common/utils/assign-poly', 'ember-common/templates/components/ht-table', 'ember-common/-private/column'], function (exports, _fmt, _assignPoly, _htTable, _column) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var keys = Object.keys;
	var get = Ember.get,
	    set = Ember.set,
	    getWithDefault = Ember.getWithDefault,
	    setProperties = Ember.setProperties,
	    getProperties = Ember.getProperties,
	    computed = Ember.computed,
	    observer = Ember.observer,
	    isNone = Ember.isNone,
	    A = Ember.A,
	    on = Ember.on,
	    compare = Ember.compare,
	    typeOf = Ember.typeOf,
	    run = Ember.run,
	    Component = Ember.Component,
	    assert = Ember.assert,
	    S = Ember.String,
	    O = Ember.Object,
	    jQ = Ember.$;


	var assign = Object.assign || Ember.assign || _assignPoly.default; // for Ember 2.4

	var NOT_SORTED = -1;

	var defaultMessages = {
		searchLabel: 'Search: ',
		searchPlaceholder: '',
		columnsTitle: 'Columns',
		columnsShowAll: 'Show All',
		columnsHideAll: 'Hide All',
		columnsRestoreDefaults: 'Restore Defaults',
		tableSummary: 'Showing %@ - %@ of %@',
		allColumnsAreHidden: 'All columns are hidden. Use the columns dropdown to show some of them',
		noDataToShow: 'No records to show'
	};
	var defaultIcons = {
		sortAsc: 'chevron up icon',
		sortDesc: 'chevron down icon',
		columnVisible: 'check circle outline icon',
		columnHidden: 'radio icon',
		navFirst: 'angle double left icon',
		navPrev: 'angle left icon',
		navNext: 'angle right icon',
		navLast: 'angle double right icon',
		caret: 'caret',
		expandRow: 'plus square outline blue icon',
		collapseRow: 'minus square outline blue icon'
	};
	var defaultCssClasses = {
		outerTableWrapper: 'ht-table-wrapper',
		innerTableWrapper: 'inner-table-wrapper',
		innerTableSticky: 'sticky-table-wrapper',
		table: 'ui celled striped selectable table',
		globalFilterWrapper: 'ui search pull-left',
		columnsDropdownWrapper: 'ui right floated dropdown labeled right icon basic button',
		columnsDropdownButtonWrapper: 'btn-group',
		columnsDropdown: 'dropdown-menu',
		theadCell: 'table-header',
		theadCellNoSorting: 'table-header-no-sorting',
		theadCellNoFiltering: 'table-header-no-filtering',
		tfooterWrapper: 'ui grid table-footer clearfix',
		footerSummary: 'table-summary',
		footerSummaryNumericPagination: 'three wide column',
		footerSummaryDefaultPagination: 'twelve wide column',
		pageSizeWrapper: 'two wide column',
		pageSizeSelectWrapper: 'pull-right',
		paginationWrapper: 'table-nav',
		paginationWrapperNumeric: 'seven wide column',
		paginationWrapperDefault: 'two wide column',
		buttonDefault: 'ui button',
		noDataCell: '',
		collapseRow: 'collapse-row',
		expandRow: 'expandRow',
		clearFilterIcon: 'remove icon',
		clearAllFiltersIcon: 'red remove icon'
	};

	var conditionals = {
		string: [{
			text: 'Equals',
			value: '==='
		}, {
			text: 'Does not equal',
			value: '!=='
		}, {
			text: 'Like',
			value: '%*%',
			isDefault: true
		}, {
			text: 'Not like',
			value: '%!%'
		}, {
			text: 'Starts with',
			value: '%*'
		}, {
			text: 'Ends with',
			value: '*%'
		}],
		number: [{
			text: 'Equals',
			value: '===',
			isDefault: true
		}, {
			text: 'Does not equal',
			value: '!=='
		},
		// {
		// 	text: 'Like',
		// 	value: '%*%',
		// 	isDefault: true
		// },
		// {
		// 	text: 'Not like',
		// 	value: '%!%'
		// },
		{
			text: 'Greater than',
			value: '>'
		}, {
			text: 'Greater than or equal',
			value: '>='
		}, {
			text: 'Less than',
			value: '<'
		}, {
			text: 'Less than or equal',
			value: '<='
		}],
		boolean: [{
			text: 'Is',
			value: '===',
			isDefault: true
		}, {
			text: 'Is not',
			value: '!=='
		}],
		date: [{
			text: 'Equals',
			value: '===',
			isDefault: true
		}, {
			text: 'Does not equal',
			value: '!=='
		}, {
			text: 'Greater than',
			value: '>'
		}, {
			text: 'Greater than or equal',
			value: '>='
		}, {
			text: 'Less than',
			value: '<'
		}, {
			text: 'Less than or equal',
			value: '<='
		}],
		time: [{
			text: 'Equals',
			value: '===',
			isDefault: true
		}, {
			text: 'Does not equal',
			value: '!=='
		}, {
			text: 'Greater than',
			value: '>'
		}, {
			text: 'Greater than or equal',
			value: '>='
		}, {
			text: 'Less than',
			value: '<'
		}, {
			text: 'Less than or equal',
			value: '<='
		}],
		datetime: [{
			text: 'Greater than',
			value: '>',
			isDefault: true
		}, {
			text: 'Greater than or equal',
			value: '>='
		}, {
			text: 'Less than',
			value: '<'
		}, {
			text: 'Less than or equal',
			value: '<='
		}]
	};

	function isSortedByDefault(column) {
		return column.sortPrecedence > NOT_SORTED;
	}

	/**
  * Convert some string to the human readable one
  *
  * @param {string} name value to convert
  * @returns {string}
  */
	function propertyNameToTitle(name) {
		return S.capitalize(S.dasherize(name).replace(/\-/g, ' '));
	}

	function optionStrToObj(option) {
		return { value: option, label: option };
	}

	/**
  * Updates <code>filterOptions</code> for column which use <code>filterWithSelect</code>
  * and don't have <code>predefinedFilterOptions</code>
  * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
  * where data is component's <code>data</code>
  */
	function getFilterOptionsCP(propertyName) {
		return computed('data.@each.' + (propertyName.indexOf('.') === -1 ? propertyName : propertyName.split('.')[0]), function () {
			var data = get(this, 'data');
			var predefinedFilterOptions = get(this, 'predefinedFilterOptions');
			var filterWithSelect = get(this, 'filterWithSelect');
			if (filterWithSelect && 'array' !== typeOf(predefinedFilterOptions)) {
				var options = A(data.mapBy(propertyName)).compact();
				if (get(this, 'sortFilterOptions')) {
					options = options.sort();
				}
				return A([''].concat(options)).uniq().map(optionStrToObj);
			}
			return [];
		});
	}

	/**
  * data -> filteredContent -> arrangedContent -> visibleContent
  *
  * @class HTTable
  * @extends Ember.Component
  */
	exports.default = Component.extend({

		layout: _htTable.default,

		conditionals: conditionals,

		/**
   * Number of records shown on one table-page (size of the <code>visibleContent</code>)
   *
   * @type number
   * @name pageSize
   * @default 10
   */
		pageSize: 10,

		/**
   * @type {number}
   * @name currentPageNumber
   * @default 1
   */
		currentPageNumber: 1,

		/**
   * @type {string[]}
   * @name sortProperties
   * @default []
   */
		sortProperties: A([]),

		/**
   * Determines if multi-columns sorting should be used
   *
   * @type {boolean}
   * @name multipleColumnsSorting
   * @default false
   */
		multipleColumnsSorting: true,

		/**
   * Determines if component footer should be shown on the page
   *
   * @type {boolean}
   * @name showComponentFooter
   * @default true
   */
		showComponentFooter: true,

		/**
   * Determines if numeric pagination should be used
   *
   * @type {boolean}
   * @name useNumericPagination
   * @default false
   */
		useNumericPagination: false,

		/**
   * Determines if columns-dropdown should be shown
   *
   * @type {boolean}
   * @name showColumnsDropdown
   * @default true
   */
		showColumnsDropdown: true,

		/**
   * Determines if filtering by columns should be available to the user
   *
   * @type {boolean}
   * @name useFilteringByColumns
   * @default true
   */
		useFilteringByColumns: true,

		/**
   * @type {string}
   * @name filterString
   * @default ''
   */
		filterString: '',

		filterStringObserver: observer('filterString', function () {
			this.userInteractionObserver();
		}),

		/**
   * Determines if filtering (global and by column) should ignore case
   *
   * @type {boolean}
   * @name filteringIgnoreCase
   * @default false
   */
		filteringIgnoreCase: false,

		/**
   * Determines if filtering should be done by hidden columns
   * Notice: after changing this value filtering results will be updated only after filter options are changed
   *
   * @type {boolean}
   * @name doFilteringByHiddenColumns
   * @default true
   */
		doFilteringByHiddenColumns: true,

		/**
   * Determines if "Global filter"-field should be shown
   *
   * @type {boolean}
   * @name showGlobalFilter
   * @default true
   */
		showGlobalFilter: true,

		/**
   * Determines if focus should be on the "Global filter"-field on component render
   *
   * @type {boolean}
   * @name focusGlobalFilter
   * @default false
   */
		focusGlobalFilter: false,

		/**
   * Determines if <code>processedColumns</code> will be updated if <code>columns</code> are changed (<code>propertyName</code> and
   * <code>template</code> are observed)
   * <b>IMPORTANT</b> All filter, sort and visibility options will be dropped to the default values while updating
   *
   * @type {boolean}
   * @name columnsAreUpdateable
   * @default false
   */
		columnsAreUpdateable: false,

		/**
   * <code>columns</code> fields which are observed to update shown table-columns
   * It is used only if <code>columnsAreUpdateable</code> is <code>true</code>
   *
   * @type {string[]}
   * @name columnFieldsToCheckUpdate
   * @default ['propertyName', 'template']
   */
		columnFieldsToCheckUpdate: A(['propertyName', 'template']),

		/**
   * All table records
   *
   * @type {Ember.Object[]}
   * @name data
   * @default []
   */
		data: A([]),

		columns: A([]),

		processedColumns: A([]),

		messages: O.create({}),

		classes: O.create({}),

		icons: O.create({}),

		groupedHeaders: A([]),

		simplePaginationTemplate: 'components/ht-table/simple-pagination',

		numericPaginationTemplate: 'components/ht-table/numeric-pagination',

		tableFooterTemplate: 'components/ht-table/table-footer',

		componentFooterTemplate: 'components/ht-table/component-footer',

		pageSizeTemplate: 'components/ht-table/page-size',

		showPageSize: true,

		globalFilterTemplate: 'components/ht-table/global-filter',

		columnsDropdownTemplate: 'components/ht-table/columns-dropdown',

		headerSortingRowTemplate: 'components/ht-table/header-row-sorting',

		headerSortingIconsTemplate: 'components/ht-table/header-sorting-icons',

		advancedFilteringTemplate: 'components/ht-table/advanced-filtering',

		headerGroupedRowsTemplate: 'components/ht-table/header-rows-grouped',

		rowTemplate: 'components/ht-table/row',

		/**
   * Template for expanded row
   *
   * @type {string}
   * @default 'components/ht-table/expanded-row'
   * @name expandedRowTemplate
   */
		expandedRowTemplate: 'components/ht-table/expanded-row',

		/**
   * Indexes of the expanded rows
   * It's set to the initial value when current page or page size is changed
   *
   * @type {number[]}
   * @private
   * @name _expandedRowIndexes
   */
		_expandedRowIndexes: null,

		/**
   * true - allow to expand more than 1 row
   * false - only 1 row may be expanded in the same time
   *
   * @type {boolean}
   * @default false
   * @name multipleExpand
   */
		multipleExpand: false,

		/**
   * @type {object[]}
   * @private
   * @name _selectedItems
   */
		_selectedItems: null,

		/**
   * @type {boolean}
   * @default false
   * @name multipleSelect
   */
		multipleSelect: false,

		/**
   * Action-name sent on user interaction
   *
   * @type {string}
   * @default 'displayDataChanged'
   * @name displayDataChangedAction
   */
		displayDataChangedAction: 'displayDataChanged',

		/**
   * Determines if action on user interaction should be sent
   *
   * @default false
   * @type {boolean}
   * @name sendDisplayDataChangedAction
   */
		sendDisplayDataChangedAction: false,

		/**
   * Action-name sent on change of visible columns
   *
   * @type {string}
   * @default 'columnsVisibilityChanged'
   * @name columnsVisibilityChangedAction
   */
		columnsVisibilityChangedAction: 'columnsVisibilityChanged',

		/**
   * Determines if action on change of visible columns should be sent
   *
   * @default false
   * @type {boolean}
   * @name sendColumnsVisibilityChangedAction
   */
		sendColumnsVisibilityChangedAction: false,

		/**
   * List of the currently visible columns
   *
   * @type {Ember.Object[]}
   * @default []
   * @name visibleProcessedColumns
   */
		visibleProcessedColumns: computed.filterBy('processedColumns', 'isVisible', true),

		/**
   * True if all processedColumns are hidden by <code>isHidden</code>
   *
   * @type {boolean}
   * @name allColumnsAreHidden
   */
		allColumnsAreHidden: computed('processedColumns.@each.isHidden', function () {
			var processedColumns = get(this, 'processedColumns');
			return processedColumns.length > 0 && processedColumns.isEvery('isHidden', true);
		}),

		/**
   * @type {boolean}
   */
		globalFilterUsed: computed.notEmpty('filterString'),

		/**
   * @type {boolean}
   */
		columnFilterUsed: computed('processedColumns.@each.filters', function () {
			return get(this, 'processedColumns').isAny('filterUsed');
		}),

		/**
   * Global filter or filter by any column is used
   *
   * @type {boolean}
   */
		anyFilterUsed: computed('globalFilterUsed', 'columnFilterUsed', function () {
			return get(this, 'globalFilterUsed') || get(this, 'columnFilterUsed');
		}),

		/**
   * Number of pages
   *
   * @type {number}
   * @name pagesCount
   */
		pagesCount: computed('arrangedContent.[]', 'pageSize', function () {
			var pagesCount = get(this, 'arrangedContent.length') / parseInt(get(this, 'pageSize'), 10);
			return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
		}),

		/**
   * List of links to the page
   * Used if <code>useNumericPagination</code> is true
   * @typedef {object} visiblePageNumber
   * @property {boolean} isLink
   * @property {boolean} isActive
   * @property {string} label
   *
   * @type {visiblePageNumber[]}
   * @name visiblePageNumbers
   */
		visiblePageNumbers: computed('arrangedContentLength', 'pagesCount', 'currentPageNumber', function () {
			var _getProperties = getProperties(this, 'pagesCount', 'currentPageNumber'),
			    pagesCount = _getProperties.pagesCount,
			    currentPageNumber = _getProperties.currentPageNumber;

			var notLinkLabel = '...';
			var groups = []; // array of 8 numbers
			var labels = A([]);
			groups[0] = 1;
			groups[1] = Math.min(1, pagesCount);
			groups[6] = Math.max(1, pagesCount);
			groups[7] = pagesCount;
			groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
			groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
			groups[2] = Math.floor((groups[1] + groups[3]) / 2);
			groups[5] = Math.floor((groups[4] + groups[6]) / 2);

			for (var n = groups[0]; n <= groups[1]; n++) {
				labels[n] = n;
			}
			var userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;
			if (userGroup2) {
				labels[groups[2]] = notLinkLabel;
			}
			for (var i = groups[3]; i <= groups[4]; i++) {
				labels[i] = i;
			}
			var userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;
			if (userGroup5) {
				labels[groups[5]] = notLinkLabel;
			}
			for (var _i = groups[6]; _i <= groups[7]; _i++) {
				labels[_i] = _i;
			}
			return A(labels.compact().map(function (label) {
				return {
					label: label,
					isLink: label !== notLinkLabel,
					isActive: label === currentPageNumber
				};
			}));
		}),

		/**
   * Are buttons "Back" and "First" enabled
   *
   * @type {boolean}
   * @name gotoBackEnabled
   */
		gotoBackEnabled: computed.gt('currentPageNumber', 1),

		/**
   * Are buttons "Next" and "Last" enabled
   *
   * @type {boolean}
   * @name gotoForwardEnabled
   */
		gotoForwardEnabled: computed('currentPageNumber', 'pagesCount', function () {
			return get(this, 'currentPageNumber') < get(this, 'pagesCount');
		}),
		/**
   * @type {Ember.Object[]}
   * @name filteredContent
   */
		filteredContent: computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filters', function () {
			var _getProperties2 = getProperties(this, 'processedColumns', 'data', 'useFilteringByColumns', 'filteringIgnoreCase', 'doFilteringByHiddenColumns'),
			    processedColumns = _getProperties2.processedColumns,
			    data = _getProperties2.data,
			    useFilteringByColumns = _getProperties2.useFilteringByColumns,
			    filteringIgnoreCase = _getProperties2.filteringIgnoreCase,
			    doFilteringByHiddenColumns = _getProperties2.doFilteringByHiddenColumns;

			var filterString = get(this, 'filterString');

			if (!data) {
				return A([]);
			}

			var _processedColumns = processedColumns;
			if (!doFilteringByHiddenColumns) {
				_processedColumns = _processedColumns.filterBy('isHidden', false);
			}

			// global search
			var globalSearch = data.filter(function (row) {
				return _processedColumns.length ? _processedColumns.any(function (c) {
					var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
					if (filterFor) {
						var cellValue = '' + get(row, filterFor);
						if (filteringIgnoreCase) {
							cellValue = cellValue.toLowerCase();
							filterString = filterString.toLowerCase();
						}
						return -1 !== cellValue.indexOf(filterString);
					}
					return false;
				}) : true;
			});

			if (!useFilteringByColumns) {
				return A(globalSearch);
			}

			// search by each column
			return A(globalSearch.filter(function (row) {
				return _processedColumns.length ? _processedColumns.every(function (c) {
					var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
					if (filterFor) {
						var cellValue = '' + get(row, filterFor);
						if (get(c, 'useFilter')) {
							var filters = get(c, 'filters');
							if (filters.length === 0) {
								return true;
							}

							return get(c, 'filters').filter(function (x) {
								var filterString = get(x, 'filterString');
								switch (get(c, 'type')) {
									case 'string':
										{
											if (get(c, 'filterWithSelect') && '' === filterString) {
												return true;
											}
											if (filteringIgnoreCase) {
												cellValue = cellValue.toLowerCase();
												filterString = filterString.toLowerCase();
											}
											switch (get(x, 'conditional')) {
												case '===':
													// equals
													return cellValue === filterString;
												case '!==':
													// doesn't equal
													return cellValue !== filterString;
												case '%*%':
													// contains
													return cellValue.includes(filterString);
												case '%!%':
													// doesn't contain
													return !cellValue.includes(filterString);
												case '%*':
													// starts with
													return cellValue.startsWith(filterString);
												case '*%':
													// ends with
													return cellValue.endsWith(filterString);
												default:
													return false;
											}
										}
									case 'number':
										{
											if (get(c, 'filterWithSelect') && '' === filterString) {
												return true;
											}
											var filterNumber = parseFloat(filterString);
											var cellNumber = parseFloat(cellValue);
											if (isNaN(filterNumber)) {
												return false;
											}

											switch (get(x, 'conditional')) {
												case '===':
													// equals
													return cellNumber === filterNumber;
												case '!==':
													// doesn't equal
													return cellNumber !== filterNumber;
												case '%*%':
													// contains
													return cellValue.includes(filterString);
												case '%!%':
													// doesn't contain
													return !cellValue.includes(filterString);
												case '>':
													// contains
													return cellValue > filterNumber;
												case '>=':
													// doesn't contain
													return cellValue >= filterNumber;
												case '<':
													// starts with
													return cellValue < filterNumber;
												case '<=':
													// ends with
													return cellValue <= filterNumber;
												default:
													return false;
											}
										}
									case 'boolean':
										return cellValue === filterString;
									case 'date':
										{
											var filterDate = Date.parse(filterString);
											var cellDate = Date.parse(cellValue);
											if (isNaN(filterDate) || isNaN(cellDate)) {
												return false;
											}

											filterDate = new Date(filterDate);
											filterDate.setHours(0, 0, 0, 0);
											filterDate = filterDate.getTime();

											cellDate = new Date(cellDate);
											cellDate.setHours(0, 0, 0, 0);
											cellDate = cellDate.getTime();

											switch (get(x, 'conditional')) {
												case '===':
													// equals
													return cellDate === filterDate;
												case '!==':
													// doesn't equal
													return cellDate !== filterDate;
												case '>':
													// contains
													return cellDate > filterDate;
												case '>=':
													// doesn't contain
													return cellDate >= filterDate;
												case '<':
													// starts with
													return cellDate < filterDate;
												case '<=':
													// ends with
													return cellDate <= filterDate;
												default:
													return false;
											}
										}
									default:
										return false;
								}
							}).length > 0 ? true : false;
						}
					}
					return true;
				}) : true;
			}));
		}),

		/**
   * @type {Ember.Object[]}
   * @name arrangedContent
   */
		arrangedContent: computed('filteredContent.[]', 'sortProperties.[]', function () {
			var filteredContent = get(this, 'filteredContent');
			var sortProperties = get(this, 'sortProperties').map(function (p) {
				var _p$split = p.split(':'),
				    _p$split2 = _slicedToArray(_p$split, 2),
				    prop = _p$split2[0],
				    direction = _p$split2[1];

				direction = direction || 'asc';

				return [prop, direction];
			});

			var _filteredContent = filteredContent.slice();
			return sortProperties.length ? A(_filteredContent.sort(function (row1, row2) {
				for (var i = 0; i < sortProperties.length; i++) {
					var _sortProperties$i = _slicedToArray(sortProperties[i], 2),
					    prop = _sortProperties$i[0],
					    direction = _sortProperties$i[1];

					var leftCompare = get(row1, prop);
					var rightCompare = get(row2, prop);

					// id property is always a string so set the comparison to integer if it is one
					if (prop === 'id') {
						leftCompare = parseInt(leftCompare) || leftCompare;
						rightCompare = parseInt(rightCompare) || rightCompare;
					}

					var result = compare(leftCompare, rightCompare);
					if (result !== 0) {
						return direction === 'desc' ? -1 * result : result;
					}
				}

				return 0;
			})) : _filteredContent;
		}),

		/**
   * Content of the current table page
   */
		visibleContent: computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
			var _getProperties3 = getProperties(this, 'arrangedContent', 'pageSize', 'currentPageNumber'),
			    arrangedContent = _getProperties3.arrangedContent,
			    pageSize = _getProperties3.pageSize,
			    currentPageNumber = _getProperties3.currentPageNumber;

			pageSize = parseInt(pageSize, 10);
			var startIndex = pageSize * (currentPageNumber - 1);
			if (get(arrangedContent, 'length') < pageSize) {
				return arrangedContent;
			}
			return A(arrangedContent.slice(startIndex, startIndex + pageSize));
		}),

		/**
   * Real table summary
   *
   * @type {string}
   * @name summary
   */
		summary: computed('firstIndex', 'lastIndex', 'arrangedContentLength', 'messages.tableSummary', function () {
			var _getProperties4 = getProperties(this, 'arrangedContentLength', 'firstIndex', 'lastIndex'),
			    arrangedContentLength = _getProperties4.arrangedContentLength,
			    firstIndex = _getProperties4.firstIndex,
			    lastIndex = _getProperties4.lastIndex;

			return (0, _fmt.default)(get(this, 'messages.tableSummary'), firstIndex, lastIndex, arrangedContentLength);
		}),

		/**
   * Is user on the last page
   *
   * @type {boolean}
   * @name isLastPage
   */
		isLastPage: computed.not('gotoForwardEnabled'),

		/**
   * Alias to <code>arrangedContent.length</code>
   *
   * @type {number}
   * @name arrangedContentLength
   */
		arrangedContentLength: computed.alias('arrangedContent.length'),

		/**
   * Index of the first currently shown record
   *
   * @type {number}
   * @name firstIndex
   */
		firstIndex: computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
			var _getProperties5 = getProperties(this, 'currentPageNumber', 'pageSize', 'arrangedContentLength'),
			    currentPageNumber = _getProperties5.currentPageNumber,
			    pageSize = _getProperties5.pageSize,
			    arrangedContentLength = _getProperties5.arrangedContentLength;

			return 0 === arrangedContentLength ? 0 : parseInt(pageSize, 10) * (currentPageNumber - 1) + 1;
		}),

		/**
   * Index of the last shown record
   *
   * @type {number}
   * @name lastIndex
   */
		lastIndex: computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
			var _getProperties6 = getProperties(this, 'currentPageNumber', 'pageSize', 'isLastPage', 'arrangedContentLength'),
			    currentPageNumber = _getProperties6.currentPageNumber,
			    pageSize = _getProperties6.pageSize,
			    isLastPage = _getProperties6.isLastPage,
			    arrangedContentLength = _getProperties6.arrangedContentLength;

			return isLastPage ? arrangedContentLength : currentPageNumber * parseInt(pageSize, 10);
		}),

		/**
   * List of possible <code>pageSize</code> values
   * Used to change size of <code>visibleContent</code>
   *
   * @type {number[]}
   * @default [10, 25, 50]
   * @name pageSizeValues
   */
		pageSizeValues: A([10, 25, 50]),

		/**
   * List of options for pageSize-selectBox
   * It's mapped from <code>pageSizeValues</code>
   * This value should not be set manually!
   *
   * @type {{value: string|number, label: string|number}}
   * @default []
   * @private
   */
		pageSizeOptions: A([]),

		/**
   * Show first page if for some reasons there is no content for current page, but table data exists
   *
   * @method visibleContentObserver
   * @name visibleContentObserver
   * @private
   */
		visibleContentObserver: function visibleContentObserver() {
			run.once(this, this.visibleContentObserverOnce);
		},


		/**
   * @private
   */
		visibleContentObserverOnce: function visibleContentObserverOnce() {
			var visibleContentLength = get(this, 'visibleContent.length');
			var dataLength = get(this, 'data.length');
			var currentPageNumber = get(this, 'currentPageNumber');
			if (!visibleContentLength && dataLength && currentPageNumber !== 1) {
				set(this, 'currentPageNumber', 1);
			}
		},


		/**
   * @method contentChangedAfterPolling
   * @name contentChangedAfterPolling
   * @private
   */
		contentChangedAfterPolling: function contentChangedAfterPolling() {
			run.once(this, this.contentChangedAfterPollingOnce);
		},


		/**
   * @private
   */
		contentChangedAfterPollingOnce: function contentChangedAfterPollingOnce() {
			get(this, 'filteredContent');
			this.notifyPropertyChange('filteredContent');
		},


		/**
   * Component init
   * Set visibility and filtering attributes for each column
   * Update messages used by table with user-provided messages (@see messages)
   * Update icons used by table with user-provided icons (@see icons)
   * Update classes used by table with user-provided css-classes (@see classes)
   *
   * @method setup
   * @name setup
   */
		setup: on('init', function () {
			var _this = this;

			this._setupSelectedRows();
			this._setupExpandedRows();
			this._setupColumns();
			this._setupMessages();
			this._setupIcons();
			this._setupClasses();
			this._setupPageSizeOptions();

			if (get(this, 'columnsAreUpdateable')) {
				var columnFieldsToCheckUpdate = get(this, 'columnFieldsToCheckUpdate');
				assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === typeOf(columnFieldsToCheckUpdate));
				columnFieldsToCheckUpdate.forEach(function (propertyName) {
					return _this.addObserver('columns.@each.' + propertyName, _this, _this._setupColumnsOnce);
				});
			}
			this.addObserver('visibleContent.length', this, this.visibleContentObserver);
		}),

		/**
   * Recalculate processedColumns when the columns attr changes
   */
		updateColumns: on('didReceiveAttrs', function () {
			if (get(this, 'columnsAreUpdateable')) {
				this._setupColumns();
			}
		}),

		/**
   * Focus on "Global filter" on component render
   *
   * @method focus
   * @name focus
   */
		focus: on('didInsertElement', function () {
			if (get(this, 'showGlobalFilter') && get(this, 'focusGlobalFilter')) {
				jQ('.filterString').focus();
			}

			jQ('.persistent-text-dropdown').dropdown({
				keepOnScreen: false,
				action: 'nothing'
			});
		}),

		_setupExpandedRows: function _setupExpandedRows() {
			set(this, '_expandedRowIndexes', A([]));
		},
		_setupSelectedRows: function _setupSelectedRows() {
			set(this, '_selectedItems', A([]));
		},


		/**
   * Wrapper for <code>_setupColumns</code> to call it only once when observer is fired
   *
   * @method _setupColumnsOnce
   * @name _setupColumnsOnce
   * @private
   */
		_setupColumnsOnce: function _setupColumnsOnce() {
			run.once(this, this._setupColumns);
		},


		/**
   * Create new properties for <code>columns</code> (filterString, useFilter, isVisible, defaultVisible)
   *
   * @method _setupColumns
   * @private
   * @name _setupColumns
   */
		_setupColumns: function _setupColumns() {
			var _this2 = this;

			var self = this;

			var nColumns = A(get(this, 'columns').map(function (column) {
				var c = _column.default.create(column);
				var propertyName = get(c, 'propertyName');
				var sortedBy = get(c, 'sortedBy');
				var filteredBy = get(c, 'filteredBy');

				var modelColumn = get(_this2, 'data.type.attributes._values')[Object.keys(get(_this2, 'data.type.attributes._values')).find(function (x) {
					return get(_this2, 'data.type.attributes._values')[x].name === propertyName;
				})];

				var type = 'string';

				if (column && column.type) type = column.type;else if (modelColumn && modelColumn.type) type = modelColumn.type.toLowerCase();

				setProperties(c, {
					type: type,
					data: get(_this2, 'data'),
					// filterString: get(c, 'filterString') || '',
					filters: get(c, 'filters') || A([]),
					useFilter: !isNone(filteredBy || propertyName) && !get(c, 'disableFiltering'),
					useSorting: !isNone(sortedBy || propertyName) && !get(c, 'disableSorting'),
					originalDefinition: column
				});

				if (isNone(get(c, 'mayBeHidden'))) {
					set(c, 'mayBeHidden', true);
				}

				var sortDirection = column.sortDirection,
				    sortPrecedence = column.sortPrecedence;

				var hasSortPrecedence = !isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
				var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
				var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

				setProperties(c, {
					defaultVisible: !get(c, 'isHidden'),
					sorting: defaultSorting,
					sortPrecedence: defaultSortPrecedence
				});

				if (get(c, 'filterWithSelect') && get(c, 'useFilter')) {
					var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
					var usePredefinedFilterOptions = 'array' === typeOf(predefinedFilterOptions);
					if (usePredefinedFilterOptions) {
						var types = A(['object', 'instance']);
						var allObjects = A(predefinedFilterOptions).every(function (option) {
							return types.includes(typeOf(option)) && option.hasOwnProperty('label') && option.hasOwnProperty('value');
						});
						var allPrimitives = A(predefinedFilterOptions).every(function (option) {
							return !types.includes(typeOf(option));
						});
						assert('`predefinedFilterOptions` should be an array of objects or primitives and not mixed', allObjects || allPrimitives);
						if (allPrimitives) {
							predefinedFilterOptions = predefinedFilterOptions.map(optionStrToObj);
						}

						set(c, 'filterOptions', usePredefinedFilterOptions ? predefinedFilterOptions : []);
					} else {
						if (propertyName) {
							set(c, 'filterOptions', getFilterOptionsCP(propertyName));
						}
					}
				}
				return c;
			}));
			nColumns.filterBy('propertyName').forEach(function (column) {
				var propertyName = get(column, 'propertyName');
				if (isNone(get(column, 'title'))) {
					set(column, 'title', propertyNameToTitle(propertyName));
				}
			});
			set(this, 'processedColumns', nColumns);

			// Apply initial sorting
			set(this, 'sortProperties', A());
			var filteredOrderedColumns = nColumns.sortBy('sortPrecedence').filter(function (col) {
				return isSortedByDefault(col);
			});
			filteredOrderedColumns.forEach(function (column) {
				self.send('sort', column);
				var defaultSortedBy = column.sortedBy || column.propertyName;
				var sortingArgs = [column, defaultSortedBy, column.sortDirection.toLowerCase()];
				if (get(_this2, 'multipleColumnsSorting')) {
					_this2._multiColumnsSorting.apply(_this2, sortingArgs);
				} else {
					_this2._singleColumnSorting.apply(_this2, sortingArgs);
				}
			});
		},


		/**
   * Update messages used by widget with custom values provided by user in the <code>customMessages</code>
   *
   * @method _setupMessages
   * @private
   * @name _setupMessages
   */
		_setupMessages: observer('customMessages', function () {
			var customIcons = getWithDefault(this, 'customMessages', {});
			var newMessages = {};
			assign(newMessages, defaultMessages, customIcons);
			set(this, 'messages', O.create(newMessages));
		}),

		/**
   * Update icons-classes used by widget with custom values provided by user in the <code>customIcons</code>
   *
   * @method _setupIcons
   * @private
   * @name _setupIcons
   */
		_setupIcons: function _setupIcons() {
			var customIcons = getWithDefault(this, 'customIcons', {});
			var newIcons = {};
			assign(newIcons, defaultIcons, customIcons);
			set(this, 'icons', O.create(newIcons));
		},


		/**
   * Update css-classes used by widget with custom values provided by user in the <code>customClasses</code>
   *
   * @method _setupClasses
   * @private
   * @name _setupClasses
   */
		_setupClasses: function _setupClasses() {
			var customClasses = getWithDefault(this, 'customClasses', {});
			var newClasses = {};
			assign(newClasses, defaultCssClasses, customClasses);
			set(this, 'classes', O.create(newClasses));
		},


		/**
   * Provide backward compatibility with <code>pageSizeValues</code> equal to an array with numbers and not objects
   * <code>pageSizeValues</code> is live as is, <code>pageSizeOptions</code> is used in the templates
   *
   * @private
   * @name _setupPageSizeOptions
   */
		_setupPageSizeOptions: function _setupPageSizeOptions() {
			var pageSizeOptions = get(this, 'pageSizeValues').map(optionStrToObj);
			set(this, 'pageSizeOptions', pageSizeOptions);
		},


		/**
   * Set <code>sortProperties</code> when single-column sorting is used
   *
   * @param {ModelsTable~TableColumn} column
   * @param {string} sortedBy
   * @param {string} newSorting 'asc|desc|none'
   * @method _singleColumnSorting
   * @private
   * @name _singleColumnSorting
   */
		_singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
			get(this, 'processedColumns').setEach('sorting', 'none');
			set(column, 'sorting', newSorting);
			set(this, 'sortProperties', 'none' === newSorting ? [] : [sortedBy + ':' + newSorting]);
		},


		/**
   * Set <code>sortProperties</code> when multi-columns sorting is used
   *
   * @param {ModelsTable~TableColumn} column
   * @param {string} sortedBy
   * @param {string} newSorting 'asc|desc|none'
   * @method _multiColumnsSorting
   * @private
   * @name _multiColumnsSorting
   */
		_multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
			set(column, 'sorting', newSorting);
			var sortProperties = get(this, 'sortProperties');
			var sortPropertiesMap = {};
			sortProperties.forEach(function (p) {
				var _p$split3 = p.split(':'),
				    _p$split4 = _slicedToArray(_p$split3, 2),
				    propertyName = _p$split4[0],
				    order = _p$split4[1];

				sortPropertiesMap[propertyName] = order;
			});
			delete sortPropertiesMap[sortedBy];

			var newSortProperties = A([]);
			keys(sortPropertiesMap).forEach(function (propertyName) {
				if (propertyName !== sortedBy) {
					newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
				}
			});
			if ('none' !== newSorting) {
				newSortProperties.pushObject(sortedBy + ':' + newSorting);
			}
			set(this, 'sortProperties', newSortProperties);
		},


		/**
   * send <code>displayDataChangedAction</code>-action when user does sort of filter
   * action is sent only if <code>sendDisplayDataChangedAction</code> is true (default false)
   *
   * @name userInteractionObserver
   * @method userInteractionObserver
   * @private
   */
		userInteractionObserver: function userInteractionObserver() {
			run.once(this, this.userInteractionObserverOnce);
		},


		/**
   * @private
   */
		userInteractionObserverOnce: function userInteractionObserverOnce() {
			if (get(this, 'sendDisplayDataChangedAction')) {
				var columns = get(this, 'processedColumns');
				var settings = O.create({
					sort: get(this, 'sortProperties'),
					currentPageNumber: get(this, 'currentPageNumber'),
					pageSize: parseInt(get(this, 'pageSize'), 10),
					filterString: get(this, 'filterString'),
					filteredContent: get(this, 'filteredContent'),
					selectedItems: get(this, '_selectedItems'),
					expandedRowIndexes: get(this, '_expandedRowIndexes'),
					columnFilters: {}
				});
				columns.forEach(function (column) {
					if (get(column, 'filters.length') > 0) {
						settings.columnFilters[get(column, 'propertyName')] = get(column, 'filters');
					}
				});
				this.sendAction('displayDataChangedAction', settings);
			}
		},


		/**
   * send <code>columnsVisibilityChangedAction</code>-action when user changes which columns are visible
   * action is sent only if <code>sendColumnsVisibilityChangedAction</code> is true (default false)
   */
		_sendColumnsVisibilityChangedAction: function _sendColumnsVisibilityChangedAction() {
			if (get(this, 'sendColumnsVisibilityChangedAction')) {
				var columns = get(this, 'processedColumns');
				var columnsVisibility = columns.map(function (column) {
					var options = getProperties(column, 'isHidden', 'mayBeHidden', 'propertyName');
					options.isHidden = !!options.isHidden;
					return options;
				});
				this.sendAction('columnsVisibilityChangedAction', columnsVisibility);
			}
		},


		/**
   * Force <code>arrangedContent</code> to be updated when <code>sortProperties</code> is changed
   * Currently "normal" <code>Em.computed.sort</code> has issue when sort properties is empty
   *
   * @method forceUpdateArrangedContent
   * @name forseUpdateArrangedContent
   * @private
   */
		forceUpdateArrangedContent: observer('filteredContent.[]', 'sortProperties.[]', function () {
			this.notifyPropertyChange('arrangedContent');
		}),

		/**
   * Handler for global filter and filter by each column
   *
   * @method filteringApplied
   * @name filteringApplied
   * @private
   */
		filteringApplied: observer('filterString', 'processedColumns.@each.filters', function () {
			set(this, 'currentPageNumber', 1);
			this.userInteractionObserver();
		}),

		/**
   * Handler for <code>pageSize</code> changing
   *
   * @method paginationApplied
   * @name paginationApplied
   * @private
   */
		paginationApplied: observer('pageSize', function () {
			set(this, 'currentPageNumber', 1);
			this.userInteractionObserver();
		}),

		/**
   * Collapse open rows when user change page size or moved to the another page
   *
   * @method collapseRow
   * @name collapseRow
   * @private
   */
		collapseRow: observer('currentPageNumber', 'pageSize', function () {
			set(this, '_expandedRowIndexes', A([]));
		}),

		actions: {
			sendAction: function sendAction() {
				this.sendAction.apply(this, arguments);
			},


			/**
    * @param {ModelsTable~TableColumn} column
    */
			toggleHidden: function toggleHidden(column) {
				if (get(column, 'mayBeHidden')) {
					column.toggleProperty('isHidden');
					this._sendColumnsVisibilityChangedAction();
				}
			},
			showAllColumns: function showAllColumns() {
				get(this, 'processedColumns').setEach('isHidden', false);
				this._sendColumnsVisibilityChangedAction();
			},
			hideAllColumns: function hideAllColumns() {
				// TODO: Does these need to be inside A()?
				A(get(this, 'processedColumns').filterBy('mayBeHidden', true)).setEach('isHidden', true);
				this._sendColumnsVisibilityChangedAction();
			},
			restoreDefaultVisibility: function restoreDefaultVisibility() {
				var _this3 = this;

				get(this, 'processedColumns').forEach(function (c) {
					set(c, 'isHidden', !get(c, 'defaultVisible'));
					_this3._sendColumnsVisibilityChangedAction();
				});
			},
			gotoFirst: function gotoFirst() {
				if (!get(this, 'gotoBackEnabled')) {
					return;
				}
				set(this, 'currentPageNumber', 1);
				this.userInteractionObserver();
			},
			gotoPrev: function gotoPrev() {
				if (!get(this, 'gotoBackEnabled')) {
					return;
				}
				if (get(this, 'currentPageNumber') > 1) {
					this.decrementProperty('currentPageNumber');
					this.userInteractionObserver();
				}
			},
			gotoNext: function gotoNext() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var currentPageNumber = get(this, 'currentPageNumber');
				var pageSize = parseInt(get(this, 'pageSize'), 10);
				var arrangedContentLength = get(this, 'arrangedContent.length');
				if (arrangedContentLength > pageSize * (currentPageNumber - 1)) {
					this.incrementProperty('currentPageNumber');
					this.userInteractionObserver();
				}
			},
			gotoLast: function gotoLast() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var pageSize = parseInt(get(this, 'pageSize'), 10);
				var arrangedContentLength = get(this, 'arrangedContent.length');
				var pageNumber = arrangedContentLength / pageSize;
				pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
				set(this, 'currentPageNumber', pageNumber);
				this.userInteractionObserver();
			},
			gotoCustomPage: function gotoCustomPage(pageNumber) {
				set(this, 'currentPageNumber', pageNumber);
				this.userInteractionObserver();
			},


			/**
    * @param {ModelsTable~TableColumn} column
    */
			sort: function sort(column) {
				var sortMap = {
					none: 'asc',
					asc: 'desc',
					desc: 'none'
				};
				var sortedBy = get(column, 'sortedBy') || get(column, 'propertyName');
				if (isNone(sortedBy)) {
					return;
				}
				var currentSorting = get(column, 'sorting');
				var newSorting = sortMap[currentSorting.toLowerCase()];
				var sortingArgs = [column, sortedBy, newSorting];
				if (get(this, 'multipleColumnsSorting')) {
					this._multiColumnsSorting.apply(this, sortingArgs);
				} else {
					this._singleColumnSorting.apply(this, sortingArgs);
				}
				set(this, 'currentPageNumber', 1);
				this.userInteractionObserver();
			},
			expandRow: function expandRow(index) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var multipleExpand = get(this, 'multipleExpand');
				var expandedRowIndexes = get(this, '_expandedRowIndexes');
				if (multipleExpand) {
					expandedRowIndexes.pushObject(index);
				} else {
					if (expandedRowIndexes.length === 1) {
						expandedRowIndexes.clear();
					}
					expandedRowIndexes.pushObject(index);
				}
				set(this, '_expandedRowIndexes', expandedRowIndexes);
				this.userInteractionObserver();
			},
			collapseRow: function collapseRow(index) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var expandedRowIndexes = get(this, '_expandedRowIndexes').without(index);
				set(this, '_expandedRowIndexes', expandedRowIndexes);
				this.userInteractionObserver();
			},


			/**
    * Handler for row-click
    * Toggle <code>selected</code>-state for row
    * Select only one or multiple rows depends on <code>multipleSelect</code>-value
    *
    * @param {number} index
    * @param {object} dataItem
    */
			clickOnRow: function clickOnRow(index, dataItem) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var multipleSelect = get(this, 'multipleSelect');
				var selectedItems = get(this, '_selectedItems');
				if (selectedItems.includes(dataItem)) {
					selectedItems = selectedItems.without(dataItem);
					set(this, '_selectedItems', selectedItems);
				} else {
					if (multipleSelect) {
						get(this, '_selectedItems').pushObject(dataItem);
					} else {
						if (selectedItems.length === 1) {
							get(this, '_selectedItems').clear();
						}
						get(this, '_selectedItems').pushObject(dataItem);
					}
				}
				this.userInteractionObserver();
			},


			/**
    * Clear all column filters and global filter
    */
			clearFilters: function clearFilters() {
				set(this, 'filterString', '');

				// This is dumn, but it triggers the observer in private/-column
				set(this, 'columnsCopy', A(get(this, 'processedColumns').copy()));
				get(this, 'columnsCopy').forEach(function (x) {
					return get(x, 'filters').clear();
				});
				set(this, 'processedColumns', get(this, 'columnsCopy'));
				this.userInteractionObserver();
			},
			showModal: function showModal(name) {
				set(this, 'columnsCopy', A(get(this, 'processedColumns').copy()));
				// $(`.ui.${name}.modal`).modal({content: 'ember-basic-dropdown-wormhole'});
				$('.ui.' + name + '.modal').modal('show');
				this.userInteractionObserver();
			},
			addFilter: function addFilter(column) {
				column.filters.pushObject({
					conditional: get(this, 'conditionals')[get(column, 'type')].find(function (x) {
						return x.isDefault;
					})['value'],
					filterString: ''
				});
				this.userInteractionObserver();
			},
			approveModal: function approveModal() {
				set(this, 'processedColumns', get(this, 'columnsCopy'));
				this.userInteractionObserver();
			},
			removeFilter: function removeFilter(filters, filter) {
				filters.removeObject(filter);
				this.userInteractionObserver();
			},


			/**
    * Dummy action for internal use
    */
			emptyAction: function emptyAction() {
				return true;
			}
		}

	});
});