define('ember-common/components/date-range-picker', ['exports', 'moment', 'ember-common/templates/components/date-range-picker'], function (exports, _moment, _dateRangePicker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var noop = function noop() {};

	exports.default = Ember.Component.extend({
		layout: _dateRangePicker.default,
		classNameBindings: ['containerClass'],
		attributeBindings: ['start', 'end', 'serverFormat'],
		start: undefined,
		end: undefined,
		minDate: undefined,
		maxDate: undefined,
		timePicker: false,
		timePicker24Hour: false,
		timePickerSeconds: false,
		timePickerIncrement: undefined,
		showWeekNumbers: false,
		showDropdowns: false,
		linkedCalendars: false,
		datelimit: false,
		parentEl: 'body',
		format: 'MMM D, YYYY',
		serverFormat: 'YYYY-MM-DD',
		rangeText: Ember.computed('start', 'end', function () {
			var format = this.get('format');
			var serverFormat = this.get('serverFormat');
			var start = this.get('start');
			var end = this.get('end');
			if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
				return (0, _moment.default)(start, serverFormat).format(format) + this.get('separator') + (0, _moment.default)(end, serverFormat).format(format);
			}
			return '';
		}),
		opens: null,
		drops: null,
		separator: ' - ',
		singleDatePicker: false,
		placeholder: null,
		containerClass: "form-group",
		inputClass: "form-control",
		inputClasses: Ember.computed('inputClass', function () {
			var inputClass = this.get('inputClass');
			return inputClass ? 'daterangepicker-input ' + inputClass : 'daterangepicker-input';
		}),
		buttonClasses: ['btn'],
		applyClass: null,
		cancelClass: null,
		labelClass: null,
		direction: 'ltr',
		ranges: {
			'Today': [(0, _moment.default)().startOf('day'), (0, _moment.default)().endOf('day')],
			'Yesterday': [(0, _moment.default)().subtract(1, 'days').startOf('day'), (0, _moment.default)().subtract(1, 'days').endOf('day')],
			'Last 7 Days': [(0, _moment.default)().subtract(7, 'days'), (0, _moment.default)()],
			'Last 30 Days': [(0, _moment.default)().subtract(30, 'days'), (0, _moment.default)()],
			'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
			'Last Month': [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')]
		},
		daysOfWeek: _moment.default.weekdaysMin(),
		monthNames: _moment.default.monthsShort(),
		removeDropdownOnDestroy: false,
		cancelLabel: 'Cancel',
		applyLabel: 'Apply',
		customRangeLabel: 'Custom Range',
		showCustomRangeLabel: false,
		fromLabel: 'From',
		toLabel: 'To',
		hideAction: null,
		applyAction: null,
		cancelAction: null,
		autoUpdateInput: true,
		autoApply: false,
		alwaysShowCalendars: false,
		context: undefined,
		firstDay: 0,
		isInvalidDate: noop,
		isCustomDate: noop,

		// Init the dropdown when the component is added to the DOM
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.setupPicker();
			Ember.run.later(function () {
				Ember.$('.daterangepicker').hide();
			}, 10);
		},
		didRender: function didRender() {
			Ember.run.later(function () {
				Ember.$('.daterangepicker').hide();
			}, 10);
			this.setOnchangeEvent();
		},
		setOnchangeEvent: function setOnchangeEvent() {
			var input = Ember.$('.daterangepicker-input');
			input.change(function () {
				Ember.$('.daterangepicker').hide();
			});
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
			this.setupPicker();
		},


		// Remove the hidden dropdown when this component is destroyed
		willDestroy: function willDestroy() {
			this._super.apply(this, arguments);

			Ember.run.cancel(this._setupTimer);

			if (this.get('removeDropdownOnDestroy')) {
				Ember.$('.daterangepicker').remove();
			}
		},
		getOptions: function getOptions() {
			var momentStartDate = (0, _moment.default)(this.get('start'), this.get('serverFormat'));
			var momentEndDate = (0, _moment.default)(this.get('end'), this.get('serverFormat'));

			var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
			var endDate = momentEndDate.isValid() ? momentEndDate : undefined;

			var momentMinDate = (0, _moment.default)(this.get('minDate'), this.get('serverFormat'));
			var momentMaxDate = (0, _moment.default)(this.get('maxDate'), this.get('serverFormat'));
			var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
			var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

			var showCustomRangeLabel = this.get('showCustomRangeLabel');

			var options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'autoUpdateInput', 'autoApply', 'timePicker', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'showCustomRangeLabel', 'linkedCalendars', 'dateLimit', 'parentEl');

			var localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'daysOfWeek', 'direction', 'firstDay', 'format', 'fromLabel', 'monthNames', 'separator', 'toLabel');

			var defaultOptions = {
				locale: localeOptions,
				showCustomRangeLabel: showCustomRangeLabel,
				startDate: startDate,
				endDate: endDate,
				minDate: minDate,
				maxDate: maxDate
			};

			if (!this.get('singleDatePicker')) {
				options.ranges = this.get('ranges');
			}

			return Object.assign({}, options, defaultOptions);
		},
		setupPicker: function setupPicker() {
			Ember.run.cancel(this._setupTimer);
			this._setupTimer = Ember.run.scheduleOnce('afterRender', this, this._setupPicker);
		},
		_setupPicker: function _setupPicker() {
			this.$('.daterangepicker-input').daterangepicker(this.getOptions());
			this.attachPickerEvents();
		},
		attachPickerEvents: function attachPickerEvents() {
			var _this = this;

			this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
				_this.handleDateRangePickerEvent('applyAction', picker);
			});

			this.$('.daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
				_this.handleDateRangePickerEvent('hideAction', picker);
			});

			this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
				_this.handleDateRangePickerEvent('cancelAction', undefined, true);
			});
		},
		handleDateRangePickerEvent: function handleDateRangePickerEvent(actionName, picker) {
			var isCancel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			var action = this.get(actionName);
			var start = void 0;
			var end = void 0;

			if (!isCancel) {
				start = picker.startDate.format(this.get('format'));
				end = picker.endDate.format(this.get('format'));
			}

			if (action) {
				(false && !(typeof action === 'function') && Ember.assert(actionName + ' for date-range-picker must be a function', typeof action === 'function'));

				this.sendAction(actionName, start, end, picker);
			} else {
				if (!this.isDestroyed) {
					this.setProperties({ start: start, end: end });
				}
			}
		}
	});
});