define('ember-common/components/ht-loader', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    inject = Ember.inject,
	    computed = Ember.computed;
	exports.default = Component.extend({
		loader: inject.service(),

		/**
   * Reads the isProcessing property of the loader service to
   * calculate if it needs to display the loader
   *
   * @property  isProcessing
   * @type boolean
   * @private
   */
		isProcessing: computed('loader.isProcessing', function () {
			return this.get('loader.isProcessing');
		}),

		/**
   * Reads the message from the loader service and displays it
   * in the loader under the loading icon
   * @property  processingMessage
   * @type boolean
   * @private
   */
		processingMessage: computed('loader.message', function () {
			return this.get('loader.message');
		})
	});
});