define('ember-common/components/server-table', ['exports', 'ember-common/components/local-table', 'ember-common/templates/components/local-table'], function (exports, _localTable, _localTable2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var get = Ember.get,
	    set = Ember.set,
	    computed = Ember.computed,
	    typeOf = Ember.typeOf,
	    run = Ember.run,
	    logger = Ember.logger,
	    $ = Ember.$;
	exports.default = _localTable.default.extend({

		layout: _localTable2.default,

		/**
   * True if data is currently being loaded from the server.
   * Can be used in the template to e.g. display a loading spinner.
   *
   * @type {boolean}
   * @property isLoading
   * @default false
   * @public
   */
		isLoading: false,

		/**
   * The property on meta to load the pages count from.
   *
   * @type {string}
   * @property metaPagesCountProperty
   * @default pagesCount
   * @public
   */
		metaPagesCountProperty: 'pagesCount',

		/**
   * The property on meta to load the total item count from.
   *
   * @type {string}
   * @property metaItemsCountProperty
   * @default itemsCount
   * @public
   */
		metaItemsCountProperty: 'itemsCount',

		/**
   * The time (ms) to wait until new data is actually loaded.
   * This can be tweaked to avoid making too many server requests.
   *
   * @type {number}
   * @property debounceDataLoadTime
   * @default 500
   * @public
   */
		debounceDataLoadTime: 500,

		/**
   * Determines if filtering by columns should be available to the user
   *
   * @type {boolean}
   * @property useFilteringByColumns
   * @default false
   * @public
   * @override
   */
		useFilteringByColumns: false,

		/**
   * The query parameters to use for server side filtering / querying.
   *
   * @type {object}
   * @property filterQueryParameters
   * @default {
   *	globalFilter: 'search',
  	*	sort: 'sort',
  	*	sortDirection: 'sortDirection',
  	*	page: 'page',
  	*	pageSize: 'pageSize'
  	* }
   * @public
   */
		filterQueryParameters: {
			globalFilter: 'search',
			sort: 'sort',
			sortDirection: 'sortDirection',
			page: 'page',
			pageSize: 'pageSize'
		},

		/**
   * Holds all property that should have an observer attached
   * @property observedProperties
   * @type {Array}
   * @private
   */
		observedProperties: ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString'],

		/**
   * This is set during didReceiveAttr and whenever the page/filters change.
   * @property filteredContent
   * @type {Array}
   * @private
   * @override
   */
		filteredContent: [],

		/**
   * For server side filtering, this property is the same as the filtered content.
   * @property visibleContent
   * @private
   * @override
   */
		visibleContent: computed.alias('arrangedContent'),

		/**
   * For server side filtering, this property is the same as the filtered content.
   * @property arrangedContent
   * @private
   * @override
   */
		arrangedContent: computed.alias('filteredContent'),

		/**
   * The total content length is get from the meta information.
   * Set metaItemsCountProperty to change from which meta property this is loaded.
   *
   * @type {number}
   * @property arrangedContentLength
   * @private
   */
		arrangedContentLength: computed('filteredContent.meta', function () {
			var itemsCountProperty = get(this, 'metaItemsCountProperty');
			var meta = get(this, 'filteredContent.meta');
			return get(meta, itemsCountProperty) || 0;
		}),

		/**
   * The pages count is get from the meta information.
   * Set metaPagesCountProperty to change from which meta property this is loaded.
   *
   * @type {number}
   * @property pagesCount
   * @private
   */
		pagesCount: computed('filteredContent.meta', 'data.meta.pageCount', function () {
			if (this.get('data.meta.pageCount')) {
				return this.get('data.meta.pageCount');
			}

			var pagesCountProperty = get(this, 'metaPagesCountProperty');
			var meta = get(this, 'filteredContent.meta');
			return get(meta, pagesCountProperty) || 1;
		}),

		/**
   * The index of the first item that is currently being shown.
   *
   * @type {number}
   * @property firstIndex
   * @private
   */
		firstIndex: computed('pageSize', 'currentPageNumber', function () {
			return get(this, 'pageSize') * (get(this, 'currentPageNumber') - 1) + 1;
		}),

		/**
   * The index of the last item that is currently being shown.
   *
   * @type {number}
   * @property lastIndex
   * @private
   */
		lastIndex: computed('pageSize', 'currentPageNumber', 'arrangedContentLength', function () {
			var pageMax = get(this, 'pageSize') * get(this, 'currentPageNumber');
			var itemsCount = get(this, 'arrangedContentLength');
			return Math.min(pageMax, itemsCount);
		}),

		/**
   * This function actually loads the data from the server.
   * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
   * @private
   */
		_loadData: function _loadData() {
			var _this = this;

			var data = get(this, 'data');
			var currentPageNumber = get(this, 'currentPageNumber');
			var pageSize = get(this, 'pageSize');
			var columns = get(this, 'processedColumns');

			var sortProperties = get(this, 'sortProperties');
			var filterString = get(this, 'filterString');

			if (!get(data, 'query')) {
				logger.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data');
				return;
			}
			var query = $.extend({}, get(data, 'query'));
			var store = get(data, 'store');
			var modelName = get(data, 'type.modelName');

			// Add pagination information
			query[get(this, 'filterQueryParameters.page')] = currentPageNumber;
			query[get(this, 'filterQueryParameters.pageSize')] = pageSize;

			// Add sorting information
			var sort = sortProperties && get(sortProperties, 'length') ? sortProperties[0] : null;
			if (sort) {
				var sortBy = sort.split(':')[0]; // eslint-disable-line ember-suave/prefer-destructuring
				var sortDirection = sort.split(':')[1].toUpperCase();

				query = this.sortingWrapper(query, sortBy, sortDirection);
			} else {
				delete query[[get(this, 'filterQueryParameters.sort')]];
				delete query[[get(this, 'filterQueryParameters.sortDirection')]];
			}

			// Add global filter
			if (filterString) {
				query[get(this, 'filterQueryParameters.globalFilter')] = filterString;
			} else {
				delete query[get(this, 'filterQueryParameters.globalFilter')];
			}

			query.filter = {};

			// Add per-column filter
			columns.forEach(function (column) {
				var filter = get(column, 'filterString');
				var filterTitle = _this.getCustomFilterTitle(column);
				var selectFilterValueProperty = get(column, 'selectFilterValueProperty');

				if (filter && !column.hideFieldValue) {
					query.filter[filterTitle] = selectFilterValueProperty ? filter.get(selectFilterValueProperty) : filter;
				} else {
					delete query.filter[filterTitle];
				}
			});

			this.safeSet(this, 'isLoading', true);
			store.query(modelName, query).then(function (newData) {
				_this.safeSet(_this, 'filteredContent', newData);
				_this.safeSet(_this, 'data.meta', newData.meta);
			}).catch(function (err) {
				console.log(err);
				_this.notifications.error('Unknown server error has occurred');
			}).finally(function () {
				var tableTop = $('.models-table-wrapper').offset().top;
				var menuHeight = $('.ui.pointing.top.fixed.menu').outerHeight(true);
				var scrollTopCoordinate = menuHeight ? tableTop - menuHeight : tableTop;

				Ember.$('html, body').animate({
					scrollTop: scrollTopCoordinate
				}, 'slow');

				_this.safeSet(_this, 'isLoading', false);
			});
		},


		/**
   * Checks if context is not destroyed/destroying and sets value in context by given key
   * @param {object} context - context
   * @param {string} key - key
   * @param {any} value - value
   */
		safeSet: function safeSet(context, key, value) {
			if (context.isDestroyed || context.isDestroying) {
				return;
			}

			set(context, key, value);
		},
		sortingWrapper: function sortingWrapper(query, sortBy, sortDirection) {
			query[this.get('filterQueryParameters.sort')] = sortBy;
			query[this.get('filterQueryParameters.sortDirection')] = sortDirection;
			query.meta = {
				sortBy: sortBy,
				sortDir: sortDirection
			};
			return query;
		},
		getCustomFilterTitle: function getCustomFilterTitle(column) {
			return get(column, 'filteredBy') || get(column, 'propertyName');
		},


		actions: {
			gotoNext: function gotoNext() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var pagesCount = get(this, 'pagesCount');
				var currentPageNumber = get(this, 'currentPageNumber');
				if (pagesCount > currentPageNumber) {
					this.incrementProperty('currentPageNumber');
					this.userInteractionObserver();
				}
			},
			gotoLast: function gotoLast() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				set(this, 'currentPageNumber', Math.ceil(get(this, 'pagesCount')));
				this.userInteractionObserver();
			},
			sort: function sort(column) {
				var sortMap = {
					none: 'asc',
					asc: 'desc',
					desc: 'none'
				};
				var sortedBy = get(column, 'sortedBy');
				if (typeOf(sortedBy) === 'undefined') {
					sortedBy = get(column, 'propertyName');
				}
				if (!sortedBy) {
					return;
				}

				var currentSorting = get(column, 'sorting');
				var newSorting = sortMap[currentSorting.toLowerCase()];
				var sortingArgs = [column, sortedBy, newSorting];
				this._singleColumnSorting.apply(this, sortingArgs);
				this.set('currentPageNumber', 1);
			}
		},

		didReceiveAttrs: function didReceiveAttrs() {
			set(this, 'filteredContent', get(this, 'data'));
		},
		_addPropertyObserver: function _addPropertyObserver() {
			run.debounce(this, this._loadData, get(this, 'debounceDataLoadTime'));
		},
		willInsertElement: function willInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);

			var observedProperties = get(this, 'observedProperties');
			observedProperties.forEach(function (propertyName) {
				_this2.addObserver(propertyName, _this2._addPropertyObserver);
			});
		},
		willDestroyElement: function willDestroyElement() {
			var _this3 = this;

			this._super.apply(this, arguments);

			var observedProperties = get(this, 'observedProperties');
			observedProperties.forEach(function (propertyName) {
				_this3.removeObserver(propertyName);
			});
		}
	});
});