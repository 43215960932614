define('ember-common/instance-initializers/application-route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: 'application-route',
		initialize: function initialize(applicationInstance) {
			if (applicationInstance && typeof applicationInstance.lookup === 'function') {
				var applicationRoute = applicationInstance.lookup('route:application');
				if (applicationRoute && typeof applicationRoute.reopen === 'function') {
					var originalBeforeModel = applicationRoute.beforeModel;

					applicationRoute.reopen({
						beforeModel: function beforeModel() {
							return originalBeforeModel.apply(applicationRoute, arguments);
						},
						activate: function activate() {
							this.notifications.setDefaultAutoClear(true);
						},

						actions: {
							showModal: function showModal(name) {
								$('body').removeClass('undetached');
								$('.ui.' + name + '.modal').modal('show');
							},
							set: function set(property, value, context) {
								context.set(property, value);
							}
						}
					});
				}
			}
		}
	};
});