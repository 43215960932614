define('ember-common/components/ht-body', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var APP = _emberGetConfig.default.APP;
	var Component = Ember.Component,
	    computed = Ember.computed,
	    inject = Ember.inject;
	exports.default = Component.extend({
		tagName: 'div',
		currentSite: APP.name,
		session: inject.service(),
		feedbackSite: computed('property', function () {
			if (_emberGetConfig.default['ember-common'] && _emberGetConfig.default['ember-common'].ahaName) {
				return 'https://' + _emberGetConfig.default['ember-common'].ahaName + '.ideas.aha.io/';
			}
			return null;
		}),
		didInsertElement: function didInsertElement() {
			// assign id to modals container generated by semantic-ui for ember-wormhole'ing of modals
			$('.ui.dimmer.modals.page').attr('id', 'modals');
		},

		actions: {
			navigate: function navigate(url) {
				window.open(url, '_blank');
			},
			authenticate: function authenticate() {
				this.sendAction(this.get('identification'), this.get('password'));
			},
			showModal: function showModal(name) {
				Ember.$('.ui.' + name + '.modal').modal('show');
			},
			logout: function logout() {
				this.get('session').invalidate();
			},
			hide: function hide() {
				Ember.$('.ui.sidebar').sidebar('hide');
			},
			redirect: function redirect(route) {
				this.sendAction('transitionToPage', route);
			}
		}
	});
});