define('ember-common/components/ht-menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    inject = Ember.inject,
	    run = Ember.run,
	    $ = Ember.$;
	exports.default = Component.extend({
		tagName: 'div',
		isMainPage: true,
		pageName: 'Page Name',
		backRoute: 'entry',
		classNames: ['ht-nav'],
		session: inject.service(),
		hideOtherProducts: false,
		didRender: function didRender() {
			/**
    *  We need wait to apply this setting so the pusher can be added and
    *  the sidebar can be moved beforehand
    */
			run.later(this, function () {
				$('#sub-sidebar').sidebar('setting', 'transition', 'overlay');
			}, 1000);
		},

		actions: {
			logout: function logout() {
				this.get('session').invalidate();
			},
			toggleSidebar: function toggleSidebar() {
				$('#sub-sidebar').sidebar('toggle');
			}
		}
	});
});