define('ember-common/mixins/component-parent', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({

		/**
   * Array of registered child components
   *
   * @property children
   * @type array
   * @protected
   */
		children: null,

		_onInit: Ember.on('init', function () {
			this.set('children', Ember.A());
		}),

		/**
   * Register a component as a child of this parent
   *
   * @method registerChild
   * @param	{object} child	Child compoment
   * @public
   */
		registerChild: function registerChild(child) {
			Ember.run.schedule('sync', this, function () {
				this.get('children').addObject(child);
			});
		},


		/**
   * Remove the child component from this parent component
   *
   * @method removeChild
   * @param	{object} child	Child component
   * @public
   */
		removeChild: function removeChild(child) {
			this.get('children').removeObject(child);
		}
	});
});