define('ember-common/helpers/token-data-property', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Helper = Ember.Helper,
	    inject = Ember.inject,
	    getOwner = Ember.getOwner,
	    observer = Ember.observer,
	    assert = Ember.assert;
	exports.default = Helper.extend({
		session: inject.service(),
		onNewUser: observer('session.session.content.authenticated', function () {
			this.recompute();
		}),
		compute: function compute(params /* , hash*/) {
			assert('The token-data-property helper only accepts 1 parameter. No more, no less. For now at least.', params.length === 1);
			var authenticator = getOwner(this).lookup('authenticator:jwt');
			var session = this.get('session.session.content.authenticated');
			var tokenProperty = {};

			if (session && Object.keys(session).length > 0) {
				tokenProperty = authenticator.getTokenData(session.token)[params[0]];
				assert('You passed an invalid property name to token-data-property.  Possible values right now are [name, username, exp, groups]', tokenProperty !== undefined);
				return tokenProperty;
			}
			return tokenProperty;
		}
	});
});